/* ProtocolUpsellCard.module.scss */

.dash-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f9fbfe;
}

.dash {
  position: relative;
}

.dash-card {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  overflow: clip;
  padding: 24px;
  gap: 24px;
  border-radius: 16px;
  box-shadow: 0 8px 24px 0 rgba(24, 39, 130, 0.12);
  @media (min-width: 834px) {
    padding: 48px 64px;
  }
}

.user-header {
  &__greeting {
    color: #0c1442;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  &__name {
    color: #0c1442;
    font-size: 32px;
    font-weight: 600;
    line-height: 36px;
  }
  &__avatar {
  }
}

.product-card {
  &__product-image {
    position: absolute;
    left: -29px;
    top: 2px;
    width: 200.872px;
    height: 146px;
    @media (min-width: 834px) {
      width: 243.523px;
      height: 177px;
      flex-shrink: 0;
    }
  }
  &__product-stats {
    min-height: 120px;
    margin-left: 100px;
    @media (min-width: 834px) {
      margin-left: 160px;
    }
    &__name {
      color: #0c1442;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      @media (min-width: 834px) {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
      }
    }
    &__label,
    &__value {
      font-size: 12px;
      line-height: 16px;
      @media (min-width: 834px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
    &__label {
      font-weight: 700;
    }
  }
  &__up-next {
    font-size: 12px;
    line-height: 16px;
    @media (min-width: 834px) {
      font-size: 16px;
      line-height: 24px;
    }
    &__title {
      font-weight: 700;
    }
    &__description {
    }
    &__cta {
      color: #2e4cff;
    }
  }
}

.product-upsell-card {
  &__product-image {
    position: absolute;
    left: -35px;
    top: 58px;
    width: 200.872px;
    height: 146px;
    @media (min-width: 834px) {
      position: absolute;
      left: -15px;
      top: 62px;
      width: 264.161px;
      height: 192px;
    }
  }
  &__product-content {
    margin-left: 100px;
    @media (min-width: 834px) {
      margin-left: 180px;
    }
  }
  &__title {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    @media (min-width: 834px) {
      font-size: 24px;
      line-height: 32px;
    }
  }
  &__description {
    font-size: 14px;
    line-height: 20px;
    @media (min-width: 834px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
  &__cta,
  &__learn-more {
    border-radius: 36px;
    width: 160px;
    height: 32px;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    gap: 10px;
    @media (min-width: 834px) {
      width: 200px;
      height: 44px;
      font-size: 16px;
      line-height: 24px;
    }
  }
  &__cta {
    background: linear-gradient(180deg, rgba(46, 76, 255, 0.7) 0%, #2e4cff 100%);
    color: #fff;
  }
  &__learn-more {
    color: #2e4cff;
  }
}

.badge.badge-lover {
  background: #fbe7e7;
}

.home-page-legacy-wrapper {
  position: relative;
  margin-top: -48px;
  margin-bottom: -32px;
  &.centered {
    @media (min-width: 480px) {
      margin-top: -36px;
    }
  }
  @media (min-width: 1280px) {
    padding: 0 20px;
  }
  h3 {
    @media (min-width: 834px) {
      font-size: 48px;
      line-height: 54px;
    }
  }
  .card.customer-card-content {
    box-sizing: border-box;
    position: relative;
    overflow: clip;
    padding: 24px;
    gap: 24px;
    border-radius: 16px;
    border: none;
    box-shadow: 0 8px 24px 0 rgba(24, 39, 130, 0.12);
    --tw-drop-shadow: none;
    filter: none;
    background: white;
    @media (min-width: 834px) {
      padding: 48px 64px;
    }
    .accordion {
      margin: -24px 0;
      .accordion-item {
      }
    }
    h2.title-format {
      font-size: 20px;
      line-height: 28px;
      @media (min-width: 834px) {
        font-size: 28px;
        line-height: 36px;
      }
    }
    h4 {
      font-size: 14px;
      line-height: 20px;
    }
    h5 {
      font-size: 16px;
      line-height: 20px;
      @media (min-width: 834px) {
        font-size: 28px;
        line-height: 36px;
      }
    }
    p {
      font-size: 14px;
      line-height: 20px;
      @media (min-width: 834px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
    button {
      padding-top: 0.8em;
      padding-bottom: 0.8em;
    }
  }
}
