/* ProtocolUpsellCard.module.scss */

.messages-footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  box-shadow: 0px 0px 25px 0px rgba(15, 25, 83, 0.2);
}

.red-text {
  color: #ff542e;
}

.red-circle {
  width: 8px;
  height: 8px;
  background-color: #ff542e;
  border-radius: 50%;
  position: absolute;
  top: -6px;
  right: -6px;
}

button .red-circle {
  top: 0;
  right: -1px;
}
