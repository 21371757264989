@import '../../../css/colors';

.referral-source-modal.why-cancel {
  .protocol.warrior span:before {
    left: 29px;
    top: -3px;
  }
  .default-button {
    text-transform: initial;
  }
  .question-steps {
    color: #757575;
    font-size: 12px;
    margin-bottom: 10px;
  }
  .help_copy {
    font-size: 14px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .referral-source-modal__title {
    width: 100%;
    padding: 40px 0 30px 0;
  }

  .question_title {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 25px;

    &__king {
      position: relative;
      text-transform: capitalize;

      &:before {
        content: '';
        position: absolute;
        background: url('images/i-title-products-king.svg') center no-repeat;
        background-size: contain;
        width: 15px;
        height: 15px;
        left: -7px;
        top: -8px;
        transform: rotate(-30deg);
      }
    }

    &__lover {
      position: relative;
      text-transform: capitalize;

      &:before {
        content: '';
        position: absolute;
        background: url('images/i-title-products-lover.svg') center no-repeat;
        background-size: contain;
        width: 12px;
        height: 12px;
        top: -8px;
        left: -4px;
      }
    }
  }

  .referral-source-modal__button-active {
    background-color: var(--primary-color);
  }

  .bp5-icon.bp5-icon-cross svg {
    fill: #c7c7c7 !important;
  }

  .referral-source-modal__image {
    width: 46%;
    object-fit: cover;
  }

  .referral-source-modal__content {
    width: 56%;
  }

  .referral-source-modal__button {
    margin-top: 20px;
    bottom: 20px;
    padding: 0px 32px;
    display: inline-block;

    @media (max-width: 620px) {
      width: 100%;
    }
  }
}
