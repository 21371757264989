.referral-source-modal {
  padding: 0px !important;
  width: 98% !important;
  background: #ffffff !important;
  position: relative;
  max-width: 800px;

  .primary-button {
    border-radius: 4px;
  }

  & > div:nth-child(1) {
    display: block;
    margin: 0px;

    & > div:nth-child(2) {
      position: absolute;
      z-index: 1;
      right: 10px;
      top: 6px;
    }
  }

  .bp5-icon-cross {
    svg {
      fill: #ffffff !important;
    }
  }
  &.having-troubles {
    .bp5-icon-cross {
      svg {
        fill: #c7c7c7 !important;
      }
    }
    .referral-source-modal__image {
      width: 44%;
    }
  }

  &__title {
    font-family: Cambon;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.4;
    color: #272833;
    padding: 40px 40px 25px 40px;
    width: 60%;
  }

  &__content {
    color: #414141;
    padding: 0px 40px 40px 40px;
    width: 60%;
  }

  &__options {
    .help-copy {
      font-size: 14px;
    }
    .hs2-checkbox {
      margin-bottom: 5px;
    }

    .hs2-checkbox__children {
      font-size: 14px;
      color: #414141;
      width: 100%;

      span {
        color: #757575;
        margin-left: 4px;
      }
    }
  }

  &__image {
    position: absolute;
    right: 0px;
    top: 0px;
    bottom: 0px;
    width: 40%;
    height: 100%;
    object-fit: cover;
    border-radius: 0px 6px 6px 0px;
  }

  &__other {
    border: none;
    border-bottom: 1px solid #d9d9d9;
    font-size: 14px;
    color: #414141;
    width: 100%;
    padding: 0px 0px 5px 0px;
    margin-bottom: -5px;
  }

  &__button {
    margin-top: 45px;
    border-radius: 4px;
    background: #e1e1e1;
    font-size: 18px;
    color: #ffffff;
    transition: all 0.1s linear;
    text-align: center;
    height: 55px;
    line-height: 55px;
    cursor: not-allowed;

    &-active {
      background: #3c9506;
      cursor: pointer;
    }
  }

  &__link {
    border-bottom: 1px solid;
    font-weight: 600;
    color: var(--primary-color);
  }
}

@media (max-width: 620px) {
  .referral-source-modal {
    &__content,
    &__title {
      width: 100% !important;
    }

    &__image {
      display: none;
    }

    .bp5-icon-cross {
      svg {
        fill: #000000 !important;
      }
    }
  }
}
