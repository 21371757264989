.input_mask__shell {
  position: relative;
}
.input_mask__shell .input_mask__span {
  position: absolute;
  color: #ccc;
  pointer-events: none;
  z-index: 0;
  padding-top: 0;
  padding-bottom: 0;
  border-color: transparent !important;
  display: flex;
  align-items: center;
  margin-top: 1px;
}
.input_mask__shell .input_mask__span i {
  /* any of these 3 will work */
  color: transparent;
  opacity: 0;
  visibility: hidden;
}

@media (min-width: 480px) {
  .input_mask__shell .input_mask__span {
    height: 56px;
    line-height: 56px;
  }
  .bp5-icon {
    &.bp5-icon-tick {
      padding-top: 8px;
    }
    &.bp5-icon-error {
      padding-top: 8px;
    }
  }
}
