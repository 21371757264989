.checkbox-component {
  margin: 8px 16px 0 16px;
}

@media (min-width: 480px) {
  .checkbox-component {
    margin-top: 16px;
  }
}

.multi-select-question__other_card.bp5-card {
  box-shadow:
    0px 4px 8px rgba(90, 91, 106, 0.2),
    0px 2px 4px rgba(58, 58, 68, 0.2);
}

.multi-select-question__other_textarea {
  width: 100%;
  height: 100%;
  border: none;
  padding: 16px;
}
