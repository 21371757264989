.customer-card-content {
  padding-left: 72px;
  padding-right: 72px;
  flex-direction: column;
  align-self: stretch;
  box-sizing: content-box;
  --tw-drop-shadow: drop-shadow(0 0 13px rgb(0 0 0 / 0.05)) drop-shadow(0 0 5px rgb(0 0 0 / 0.08));

  @media (max-width: 1140px) {
    padding: 32px;
    margin: 20px;
  }

  .accordion {
    .accordion-item {
      border-top: 1px solid #eef0ff;

      &:first-child {
        border-top: none;
      }
    }
  }

  hr:last-of-type {
    display: none;
  }
}

.centered {
  .customer-card-content {
    h5 {
      text-align: left;
      line-height: 36px;
    }
  }

  .font-48 {
    font-size: 48px;
  }
}

.customer-background-gray {
  border-color: #eef0ff;
}

.dropdown-content-alignment {
  padding-bottom: 20px;

  ul {
    list-style: disc outside none;
    list-style: initial;

    li {
      margin: 0px 0px 10px 20px;
    }
  }
}

.dropdown-paragraph-content {
  margin-top: 20px;
  font-weight: 300;
}

.gray-border-top {
  border-top: 1px solid #eef0ff;
}
