@import '../../../../css/colors';

.registration-flow {
  max-width: 90%;
  margin: 50px auto;

  @media (max-width: 500px) {
    margin: 25px auto;
  }
  &.center {
    text-align: center;
  }
  &.registration-flow__intro-questionnaire-results {
    .description {
      max-width: 400px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .exist {
    font-size: 12px;
    text-align: center;
    margin: 10px 0px 30px 0px;

    a {
      font-weight: 700;
      text-decoration: underline;
    }
  }

  .errors {
    background: #ff3333;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #fff;
    padding: 20px 0px;
    text-transform: uppercase;
    margin-bottom: 30px;
  }

  .notice {
    margin: 20px 0px;
  }

  .welcome-title {
    font-size: 20px;
    font-family: Cambon;
    font-weight: 600;
  }

  .title {
    font-weight: 700;
    font-size: 45px;
    line-height: 1.28;
    display: flex;
    align-items: center;

    @media (max-width: 500px) {
      font-size: 34px;
    }

    &_image {
      span {
        flex: 0 0 75%;

        @media (max-width: 500px) {
          flex: 0 0 100%;
        }
      }

      img {
        width: 130px;

        @media (max-width: 500px) {
          display: none;
        }
      }
    }
  }

  .description {
    font-weight: 300;
    font-size: 14px;
    line-height: 1.4;
    margin: 20px 0px;
  }

  .button-next {
    border-radius: 8px;
    height: 65px;
    font-weight: 600;
    font-size: 18px;
  }

  .link-as-button {
    display: block;
    text-align: center;
    line-height: 65px;
  }

  .rules {
    margin: 35px 0px;

    &__item {
      margin: 15px 0px;

      .hs2-checkbox {
        margin-top: 0px;

        label {
          align-items: flex-start;
        }

        .hs2-checkbox__children {
          font-weight: 300;
          font-size: 12px;
          line-height: 1.4;
          color: $gray_3;
          letter-spacing: 0.15px;

          a {
            font-weight: 600;
            text-decoration: underline;
          }
        }
      }

      .hs2-text-input__error {
        font-weight: 300;
      }
    }
  }

  .links {
    text-align: center;

    &__item {
      margin: 30px 0px;
      font-size: 14px;
      line-height: 1.4;
      font-weight: 400;
      color: $gray_3;
      letter-spacing: 0.15px;
    }

    &__guarantee,
    a {
      cursor: pointer;
      font-weight: 600;
      text-decoration: underline;
      color: var(--primary-color);
    }
  }

  .inputs {
    margin: 30px 0px;

    &__item {
      margin: 10px 0px;

      .text-field {
        height: 60px;
        background: $white;
        text-align: center;
        border-radius: 4px;
        font-size: 16px;
        border: 1px solid #c7c7c7;
        padding: 0px 53px;

        &:focus {
          border-color: var(--primary-color);
        }

        &.failed {
          border-color: $warning;
        }

        &.valid {
          border-color: $success;
        }

        &:disabled {
          opacity: 1;
          background: $white;
        }

        &__phone_number {
          padding-top: 13px;

          &__label {
            color: #888888;
            position: absolute;
            left: 50%;
            transform: translate(-45%, 10px);
            pointer-events: none;
            font-size: 15px;
          }
        }
      }

      &_hidden {
        opacity: 0.5;
      }

      .dropdown-wrapper {
        .dropdown__error {
          .select__control {
            border-color: $warning;
          }
        }
        .select__control {
          height: 60px;
          background: $white;
          text-align: center;
          border-radius: 4px;
          font-size: 16px;
          border: 1px solid #c7c7c7;

          .select__input-container {
            justify-content: center;
          }

          &.bp5-active {
            border-color: var(--primary-color);
          }
        }
      }
    }
  }

  &__dropdown {
    &.dropdown-fixed {
      max-height: 285px;
      width: 450px;
      overflow-y: visible;

      @media (max-width: 500px) {
        width: 90%;
        overflow-y: visible;
      }
    }
  }

  .dropdown__helper_text {
    font-weight: 300;
    letter-spacing: 0px;
  }

  .goals-questions {
    margin: 30px 0px;

    .mb16 {
      gap: 10px;
      display: flex;
      flex-direction: column;
      margin: 0px;
    }
  }

  .health-questions,
  .goals-questions {
    box-shadow: inset 0px 0px 14px rgba(0, 0, 0, 0.05);
    background: #e5eaee;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    position: relative;

    &__item,
    .checkbox-component {
      margin: 0px;

      .hs2-checkbox {
        label {
          background: #fff;
          height: 72px;
          border: 1.5px solid #fff;
          transition: all 0.1s linear;
          border-radius: 4px;
          padding: 0px 20px;

          p {
            font-size: 14px;
            color: #19191d;
            font-weight: 700;
          }

          span {
            font-size: 14px;
            color: #19191d;
            font-weight: 300;
          }
        }

        &__checkbox {
          opacity: 0.5;
        }

        &__children {
          margin-left: 20px;
        }

        &--checked {
          label {
            border: 1.5px solid var(--primary-color);
          }

          .hs2-checkbox__checkbox {
            opacity: 1;
          }
        }
      }
    }
  }

  .health-results {
    animation: 3s ease 0s normal forwards 1 show-results;
  }
}

@keyframes show-results {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.cookie-email-modal {
  &__create_account {
    text-align: center;
    margin-top: 17px;
    display: block;
  }
  & > div > div {
    color: $black !important;
  }
}

.drop-question-answer {
  height: 72px;
  background: #ffffff;
  box-shadow:
    0px 2px 4px rgba(58, 58, 68, 0.1),
    0px 4px 8px rgba(90, 91, 106, 0.1);
  border-radius: 4px;
  padding: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 16px;
  user-select: none;

  &.-dragging {
    opacity: 0.5;
  }
  &::before {
    content: '';
    height: 20px;
    width: 12px;
    flex: 0 0 12px;
    background-image: url(../../../../../images/DragPlaceholder.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  &__icon {
    font-size: 35px;
    width: 35px;
    display: flex;
  }
  &__info {
    color: #19191d;
    font-feature-settings:
      'tnum' on,
      'lnum' on,
      'ss01' on;
  }
  &__description {
    font-weight: 300;
  }
}
