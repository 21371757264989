@import 'colors';

.centered {
  max-width: 612px !important;
}

.new-design-system {
  font-family: Inter !important;

  .title {
    color: var(--black, #0c1442);

    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }

  h2.title {
    font-size: 40px;
    line-height: 48px;

    @media (max-width: 480px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  h3.title {
    font-size: 32px;
    line-height: 40px;

    @media (max-width: 480px) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  h5.title {
    font-size: 14px;
    line-height: 20px;
  }

  h4,
  .title_with_line {
    font-family: Inter;
    font-size: 20px;

    @media (max-width: 480px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .title_with_line {
    margin-top: 0;
    border: none;
    padding: 0;
    font-weight: 500;
  }

  .default-button,
  .default-button:hover,
  .bp5-button,
  .bp5-button:hover {
    padding: 12px 0 !important;
    line-height: 20px;
    align-self: stretch;
    height: 44px !important;
    box-sizing: border-box;

    border-radius: 36px !important;
    background: var(--blue, #2e4cff) !important;

    color: $white !important;
  }

  .price_row {
    font-size: 16px;
    font-weight: 500;

    &.due_today > div {
      font-size: 14px !important;
      font-weight: 400;
    }
  }

  .due_today {
    .discount-coupons-list {
      .price_row {
        margin-top: 8px;
      }
    }
  }

  .default-button:disabled,
  .default-button:disabled:hover {
    opacity: 0.5;
    box-shadow: none;
  }

  .payment_card {
    border: none;
    box-shadow: none;
    padding: 0;
  }

  &.content {
    margin: 40px 0;
    background: $white;
    box-shadow: 0 0 25px 0 rgba(15, 25, 83, 0.2);
    padding: 48px 80px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    max-width: 451px;
    box-sizing: content-box;

    @media (max-width: 480px) {
      padding: 32px;
      margin: 20px;
    }

    .product-image {
      border-radius: 8px;
      padding: 12px 0;
      background: #f5f6fd;
      margin: 8px 0 24px 0;
      max-height: 147px;
    }

    .description {
      color: var(--black, #0c1442);
      font-feature-settings: 'ss05' on;
      /* body-dt */
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

      @media (max-width: 480px) {
        font-size: 14px;
        line-height: 20px;
      }
    }

    .hints {
      font-size: 12px;
    }

    .exist {
      margin-top: 12px;
    }

    .a {
      color: #2e4cff;
    }

    .row {
      width: 100%;
      display: flex;
      gap: 24px;

      .form-field {
        flex: 1 1 0px;
        width: 0;
      }
    }

    .coupon-code-input__button {
      float: right;
      color: var(--blue, #2e4cff);
      text-align: right;
      font-feature-settings: 'ss05' on;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-decoration: none;
    }

    .form-field,
    .payment_method_info .mt12 {
      margin: 24px 0;

      label {
        margin-bottom: 8px !important;
        display: block;
      }

      input {
        border-radius: 8px;
        border: 1px solid var(--gray-out, #a9b6ff);
        padding: 12px 16px;
        height: 48px;
        width: 100%;
        box-sizing: border-box;

        &::placeholder {
          color: var(--gray-out, #a9b6ff);
        }
      }

      &.flex {
        display: flex;
        gap: 24px;
      }

      .dropdown-wrapper label p {
        margin-bottom: 6px;
      }

      .input_mask__span,
      .select__placeholder {
        margin-top: -3px;
        color: var(--gray-out, #a9b6ff);
      }

      .input,
      .select__control {
        border-radius: 8px;
        border: 1px solid var(--gray-out, #a9b6ff);
        height: 48px;
        box-sizing: border-box;
        margin-top: -1px;
        margin-left: -1px;
        margin-right: -1px;

        .select__value-container,
        .select__input-container {
          height: 48px;
          padding-top: 0;
          margin-top: 0;
          box-sizing: border-box;
        }
      }

      .form-field-label {
        font-size: 14px;
        color: #182782;
        margin-bottom: 8px;
      }
    }
  }
}

.bp5-dialog {
  padding: 32px !important;
  color: #0c1442 !important;
  background: $white !important;
  border-radius: 8px !important;
  position: relative;

  ul {
    list-style: disc;
    margin-left: 25px;
    margin-top: 20px;

    li {
      margin-bottom: 10px;
    }
  }

  h3.title {
    font-size: 24px;
    line-height: 32px;
  }

  div {
    color: $black !important;
    font-feature-settings: 'ss05' on;
    /* body-mb */
    font-family: Inter;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .modal-close-icon {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
  }
}
