@import '../../../../../css/colors';

.dash-resources {
  position: relative;
  .carousel {
    .carousel {
      &__button {
        @media (max-width: 480px) {
          display: none;
        }
      }
    }
    .slider-wrapper {
      padding: 0 16px 24px;
    }
  }

  .slide-card {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    position: relative;
    overflow: clip;
    gap: 24px;
    border-radius: 16px;
    box-shadow: 0 8px 24px 0 rgba(24, 39, 130, 0.12);
    text-align: left;
    height: 100%;

    .slide-card {
      &__body {
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    }
  }
}
