ul.modal-list {
  margin-top: 0;

  li {
    margin-bottom: 0;
  }
}

.fixed-footer {
  position: fixed;
  bottom: 59px;
  background: #fff;
  padding: 32px 20px;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
  z-index: 10;
  text-align: center;
  left: 0;
  right: 0;

  .new-theme & {
    bottom: 0;
  }

  a {
    font-size: 14px;
    text-decoration: underline;
  }

  .default-button {
    max-width: 400px;
  }

  @media (max-width: 600px) {
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.new-theme .fixed-footer {
  a {
    text-decoration: none;
  }
}

.choose_product {
  .bp5-control-indicator {
    margin: 0.25rem 1.125rem auto 0.625rem !important;
  }
  .help_decide {
    margin: 32px 0;
    text-align: center;
    text-decoration: underline;
    color: #0066a6;
    cursor: pointer;
  }
  .radio-option {
    .price_row {
      color: #000 !important;
    }
    &._view-module__disabled__Tc8ed {
      opacity: 1 !important;

      .price_row,
      .bp5-control-indicator {
        opacity: 0.5 !important;
      }
      .titles {
        color: #000;
      }
      &:hover {
        background: none;
        box-shadow: none;
      }
    }
  }
}

.product-selection-alert {
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #000000;
  gap: 10px;
  color: #000000;

  font-family: Poppins;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.0075em;
  text-align: left;

  b {
    font-weight: 700;
  }
}
