.spinner {
  &__center {
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
  }

  &__auto {
    margin: auto;
  }

  &__text {
    padding-top: 1.75em;
    text-align: center;
  }
}
