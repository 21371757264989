.discount-coupons-container {
  .subtitle {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0.0075em;
    text-align: left;
    padding-top: 20px;
  }
  .coupon-code-input {
    &__button {
      &__apply {
        right: 14px;
      }
    }
  }
}

.discount-coupons-list {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.0075em;
  text-align: left;
  color: rgba(55, 131, 59, 1);
  margin-bottom: 24px;
  .price_row > div:first-child {
    font-size: 12px !important;
    line-height: 18px;
    font-weight: 600;
  }
  .price_row > div:last-child {
    margin-top: 10px;
  }
  .coupon_code {
    word-wrap: break-word;
    max-width: 70%;
  }

  &--new {
    margin-bottom: 0;
    .price_row {
      margin: 0;
      align-items: center;
      font-size: 14px;
      color: #349c00;
      line-height: 20px;

      .discount-message {
        display: flex;
        flex-direction: column;
        gap: 2px;

        .coupon-applied {
          font-weight: 700;
        }

        .coupon-description {
          font-weight: 400;
        }
      }

      .discount-amount {
        text-align: right;
        white-space: nowrap;

        .new-price-format {
          font-family: Inter;
          font-size: 14px;
          line-height: 20px;
          font-weight: 700;
          color: #349c00;
          display: flex;
          align-items: baseline;
          justify-content: flex-end;
          gap: 1px;

          span {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }
}
