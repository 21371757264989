.multimonth_treatment_plan_options {
  font-family: Poppins;
  letter-spacing: 0.0075em;
  border-radius: 4px;
  text-align: left;

  label {
    margin: 0 !important;
    display: block;
    width: 100%;
  }

  .bp5-control-indicator {
    display: none !important;
  }

  .special-label {
    position: absolute;
    height: 24px;
    padding-left: 10px;
    display: flex;

    img {
      height: 24px;
    }

    span {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      color: #005285;
      padding-left: 5px;
      padding-top: 3px;
    }
  }
}
