.numeric-card .bp5-card {
  box-shadow:
    0px 4px 8px rgba(90, 91, 106, 0.2),
    0px 2px 4px rgba(58, 58, 68, 0.2);
  padding: 1rem;
  padding-top: 2rem;
}
.top-level {
  display: flex;
  justify-content: space-evenly;
}
.clickable {
  cursor: pointer;
}

.down-button {
  display: flex;
  align-items: center;
  img {
    height: 48px;
  }
  @media (min-width: 480px) {
    img {
      height: 64px;
    }
  }
}
.up-button {
  display: flex;
  align-items: center;
  img {
    height: 48px;
  }
  @media (min-width: 480px) {
    img {
      height: 64px;
    }
  }
}
