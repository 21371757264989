@import '../../../../css/colors';

.progress-bar {
  padding: 14px 0;
  grid-area: progress-bar;
  align-items: center;
  box-shadow:
    0px 2px 4px rgba(58, 58, 68, 0.1),
    0px 4px 8px rgba(90, 91, 106, 0.1);

  .flex {
    justify-content: space-between;
  }

  .line-bar {
    width: 3.2px;
    height: 24px;
    margin-left: 16px;
    margin-right: 5px;
    background-color: var(--primary-color);
  }

  h6 {
    white-space: nowrap;
    margin-right: 10px;
    display: flex;
    align-items: center;

    @media (max-width: 480px) {
      font-size: 18px;
    }

    @media (max-width: 380px) {
      font-size: 16px;
    }
  }

  .bp5-progress-bar.bp5-no-stripes {
    height: 5px;
    width: 130px;

    @media (max-width: 480px) {
      width: 100px;
    }

    @media (max-width: 480px) {
      width: 80px;
    }

    .bp5-progress-meter {
      height: inherit;
      background-color: var(--primary-color);
    }
  }

  .bar {
    display: flex;
    align-items: center;
    margin-right: 16px;

    span {
      margin-left: 8px;
      font-size: 14px;
    }
  }

  &.hide-progress {
    .bar {
      display: none;
    }
  }
}
