* {
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  font-size: 100%;
  font-family: var(--font-primary);
  font-style: normal;
  line-height: initial;
  letter-spacing: var(--letter-spacing-Body1);
  font-weight: var(--font-weight-Body1);
  color: var(--grey100);
  background: var(--grey0);
}

button {
  font-family: var(--font-primary);
}

a {
  color: var(--primary-color);
}

a:hover {
  text-decoration: none;
}

*:focus {
  outline: none;
  outline-offset: 0;
}

.bp5-control input:focus ~ .bp5-control-indicator {
  outline: none;
  outline-offset: 0;
}

.bp5-button:not([class*='bp5-intent-']) {
  box-shadow: none;
  background-color: unset;
  background-image: none;
  color: unset;
}

.bp5-button:not([class*='bp5-intent-']):hover {
  box-shadow: none;
  background-clip: unset;
  background-color: unset;
}

.bp5-button .bp5-icon {
  color: unset;
}

label.bp5-label {
  font-size: 1rem;
  font-weight: bold;
  text-align: initial;
  margin-bottom: 0.625rem;
  margin-right: 1rem;
}
